import "core-js/modules/es.function.name.js";
import * as echarts from 'echarts';
export default {
  name: "DataBase",
  data: function data() {
    return {
      orgData: {},
      tokenData: {},
      userData: {},
      echartsData: {},
      echartsData2: {},
      echartsData3: {},
      positionOptions: [{
        id: 1,
        name: "园长"
      }, {
        id: 2,
        name: "园长助理"
      }, {
        id: 3,
        name: "教学负责人(教学园长、教学主任等)"
      }, {
        id: 4,
        name: "主配班老师"
      }, {
        id: 5,
        name: "其他"
      }]
    };
  },
  mounted: function mounted() {
    this.initBasic();
    this.initEcharts();
    var that = this;
    window.onresize = function () {
      return function () {
        that.drawECharts();
        that.drawECharts2();
        that.drawECharts3();
      }();
    };
  },
  methods: {
    setNumber: function setNumber(arry) {
      var tmparry = arry;
      for (var key in tmparry) {
        var number = tmparry[key];
        tmparry[key] = new Intl.NumberFormat('en-US').format(number);
        // const formattedNumber = number.toLocaleString();
        // console.log(`${key}: ${formattedNumber}`);
      }

      return tmparry;
    },
    initBasic: function initBasic() {
      var _this = this;
      var that = this;
      this.service.axiosUtils({
        requestName: "getBasicData",
        data: {},
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            that.orgData = that.setNumber(res.data.orgData);
            that.tokenData = that.setNumber(res.data.tokenData);
            that.userData = that.setNumber(res.data.userData);
          } else {
            _this.$toast.error(res.message);
          }
        }
      });
    },
    initEcharts: function initEcharts() {
      var _this2 = this;
      this.service.axiosUtils({
        requestName: "getBasicPositionData",
        data: {},
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            var resData = res.data;
            for (var i = 0; i < resData.length; i++) {
              if (resData[i].name === '') {
                resData[i].name = '未知';
              } else {
                resData[i].name = _this2.$common.getName(parseInt(resData[i].name), _this2.positionOptions);
              }
            }
            _this2.echartsData = resData;
            _this2.drawECharts();
          } else {
            _this2.$toast.error(res.message);
          }
        }
      });
      this.service.axiosUtils({
        requestName: "getBasicSourceData",
        data: {},
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            var resData = res.data;
            for (var i = 0; i < resData.length; i++) {
              if (resData[i].name === '') {
                resData[i].name = '默认';
              }
            }
            _this2.echartsData2 = resData;
            _this2.drawECharts2();
          } else {
            _this2.$toast.error(res.message);
          }
        }
      });
      this.service.axiosUtils({
        requestName: "getBasicManageData",
        data: {},
        sucFn: function sucFn(res) {
          if (res.status === 1) {
            var xData = res.data.name;
            for (var i = 0; i < xData.length; i++) {
              if (xData[i] === null) {
                xData[i] = '未知';
              }
            }
            var yData = res.data.amount;
            _this2.echartsData3 = {
              x: xData,
              y: yData
            };
            _this2.drawECharts3();
          } else {
            _this2.$toast.error(res.message);
          }
        }
      });
    },
    drawECharts: function drawECharts() {
      var that = this;
      if (document.getElementById("main")) {
        document.getElementById("main").removeAttribute('_echarts_instance_');
        // 基于准备好的dom，初始化echarts实例
        var myChart = echarts.init(document.getElementById('main'));
        // 绘制图表
        myChart.setOption({
          title: {
            textStyle: {
              fontSize: 12,
              color: "rgba(0,0,0,0.65)",
              fontWeight: 400
            }
          },
          tooltip: {
            trigger: 'item'
          },
          legend: {
            top: '5%',
            left: 'center'
          },
          series: [{
            name: '职位分布',
            type: 'pie',
            center: ['50%', '59%'],
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 16,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: that.echartsData
          }]
        });
      }
    },
    drawECharts2: function drawECharts2() {
      var that = this;
      if (document.getElementById("main2")) {
        document.getElementById("main2").removeAttribute('_echarts_instance_');
        // 基于准备好的dom，初始化echarts实例
        var myChart2 = echarts.init(document.getElementById('main2'));
        // 绘制图表
        myChart2.setOption({
          tooltip: {
            trigger: 'item'
          },
          legend: {
            top: '5%',
            left: 'center'
          },
          series: [{
            name: '渠道来源',
            type: 'pie',
            center: ['50%', '59%'],
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 16,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: that.echartsData2
          }]
        });
      }
    },
    drawECharts3: function drawECharts3() {
      var that = this;
      if (document.getElementById("main3")) {
        document.getElementById("main3").removeAttribute('_echarts_instance_');
        // 基于准备好的dom，初始化echarts实例
        var myChart3 = echarts.init(document.getElementById('main3'));
        // 绘制图表
        myChart3.setOption({
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          yAxis: {
            type: 'category',
            data: that.echartsData3.x
          },
          xAxis: {
            type: 'value'
          },
          series: [{
            name: '负责园所数量',
            data: that.echartsData3.y,
            type: 'bar',
            showBackground: true,
            barWidth: '40%',
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            }
          }]
        });
      }
    }
  }
};